<template>
    <div>
        <!-- Search header with input -->
        <div class="search-header">
            <div class="container-lg">
                <form class="search-form" @submit.prevent="submitSearch">
                    <div class="search-input-group">
                        <b-form-input autofocus size="lg" type="search" v-model="filter_search" placeholder="Search for posts..."></b-form-input>
                        <!--<span v-show="filter_search" class="removeInput" @click="removeSearchQuery">+</span>-->
                        <button class="custom-btn custom-btn-success" @click="submitSearch"><font-awesome-icon icon="search" /><span class="d-none d-sm-inline">Search</span></button>
                    </div>
                </form>
            </div>
        </div>
      <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">
        <div class="col-lg-9">
          <div class="row mb-3">
            <div class="col-md-4 mb-2 mb-sm-0">
              <select v-model="filter_post" class="form-select">
                <option value="">-- All Posts --</option>
                <option value="surveys">Surveys</option>
                <option disabled>Discussions (Coming Soon)</option>
              </select>
            </div>
            <div class="col-md-4">
              <select class="form-select" v-model="filter_category">
                <option value="">-- All Categories --</option>
                <option :key="c.id" :value="c.id" v-for="c in categories">{{ c.name }}</option>
              </select>
            </div>
            <div class="col-md-4 mb-2 mb-sm-0">
              <select class="form-select" v-model="filter_language">
                <option value="">-- Select Language --</option>
                <option v-for="l in languages" :value="l.code" :key="l.code">{{ l.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <!--
        <div class="mb-3">
          <div  class="post-status">
            <span  class="status-badge draft">
              <font-awesome-icon icon="circle" />
                {{ filter_post }}
            </span>
            <span class="status-badge inactive">
              <font-awesome-icon icon="circle" />
              {{ filter_category }}
            </span>
            <span  class="status-badge active">
              <font-awesome-icon icon="circle" />
              {{ filter_language }}
            </span>
          </div>
        </div>
        -->
      </div>
        
        <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">

            <div v-if="wait" class="d-flex align-items-center mb-4">
                <strong>Loading search results...</strong>
                <b-spinner class="ms-auto"></b-spinner>
            </div>                       

            <div class="search-results-container" transition="expand">

                <div v-if="surveys.data.length !== 0" class="results-header mb-3">
                    <h6><strong>{{ surveysCount }}</strong> result(s)</h6>
                </div>
                
                <ul class="search-results">
                    <li v-for="survey in surveys.data" :key="survey.id" class="search-result">
                        <span class="datestamp">{{ moment(survey.created_at).format("DD/MM/YYYY") }}</span>
                        <h6>
                            <a :href="`/surveys/${survey.code}/${survey.slug}`"
                                class="text-headline text-decoration-none">{{ survey.title }}</a>
                        </h6>

                        <div class="status mb-2">
                            <small v-if="!survey.published" class="text-danger font-weight-semibold">Not Published</small>
                            <small v-else class="text-success font-weight-semibold">Published</small>
                            <small v-if="!survey.active" class="text-danger font-weight-semibold">&nbsp;(Inactive)</small>
                        </div>

                        <div class="search-list-icons">
                            <ul>
                                <li>
                                    <router-link :to="`/profile/surveys/${survey.id}`" :title="`${survey.questions_count} question(s)`">
                                        <span><font-awesome-icon icon="list" /></span>
                                        <span class="count">{{ survey.questions_count }}</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="`/profile/surveys/${survey.id}/respondents`" :title="`${survey.respondents_count} response(s)`">
                                        <span><font-awesome-icon icon="users" /></span>
                                        <span class="count">{{ survey.respondents_count }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        
                        <div class="user mt-3" v-if="!survey.user.id">
                            <a href="#" :title="'View all public surveys by ' + survey.user.name">{{ survey.user.name }}</a>
                        </div>                        
                    </li>                                      <!-- End survey list item -->
                </ul>                                     
            </div>

            <!-- Empty store if there are no search results -->
            <div v-if="!wait && surveys.data.length == 0" class="posts-empty">
                <div class="posts-empty-inner">
                    <div class="posts-empty-inner_img">
                        <img loading="lazy" src="@/assets/img/search.png" width="80" height="80" alt="Empty box"/>
                    </div>
                    <h4 v-if="!filter_search">There are no search results to display.</h4>
                    <h4 v-else-if="!wait && filter_search">No matches for "{{ filter_search }}".</h4>
                    <h6>Try adjusting your keywords to find what you're looking for.</h6>
                </div>
            </div>          

         </div>
    </div>
</template>

<script>
export default {
    name: 'SearchPage',
     data() {
        return {
            loading: false,
            stats: {
                all: 0,
                drafts: 0,
                active: 0,
                inactive: 0
            },
            survey: '',
            id: '',
            title: '',
            description: '',
            created_at: '',
            questions: '',
            responses: '',

            surveys: {data: []},

            activeItem: 'allSurveys',
            filter_search: '',
            filter_post: '',
            filter_category: '',
            filter_language: '',
            isResult: false,
            categories: [],
            languages: []
        }
    },
      mounted() {
        this.getCategories()
        this.loadLanguages()
       },
      watch: {
          filter_category() {
            this.submitSearch()
        },
          filter_language() {
          this.submitSearch()
          },
        // filter_date() {
        //     this.loadSurveys()
        // },
          filter_search(a, b) {
            if (b.length >= 2) {
                this.submitSearch()
            }
        }
    },
       methods: {
        submitSearch() {
            this.wait = true
            let search = this.filter_search.replace('#', '')
            this.$http.get('/api/profile/surveys/?search=' + search + '&category=' + this.filter_category + '&lang=' + this.filter_language ).then(response => {
                this.surveys = response.data.data
                this.allCount = response.data.data.total
                this.surveysCount = response.data.data.total
              console.log(this.surveys)
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            })
        },
        removeSearchQuery() {},
         getCategories() {
           this.$http.get('/api/categories').then(res => {
             this.categories = res.data.data
           })
         },
         loadLanguages() {
           this.$http.get('/api/languages').then(response => {
             this.languages = response.data.data
           })
         },
    }
}
</script>

<style scoped>
</style>